<template>
  <div>
    <div
         v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
           <vs-th>Action</vs-th>
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">

          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Detail Document">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleOpenForm(
                        indextr
                      )
                    "
                    icon="icon-eye"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              ><span v-if="head.field.toString().includes('date')">{{
                dateFormat(data[indextr][head.field])
              }}</span>
            <span v-else>{{ data[indextr][head.field] }}</span></vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>

    <div>
      <vs-prompt
        color="information"
        title="Document Details :"
        @close="closeDetail"
        :button-accept="'OK'"
        :button-cancel="''"
        :active.sync="activePromptDetail"
      >
        <div class="con-example-prompt">
          <div>
            <div> Attachment LAF : <a
                target="_blank"
                v-if="attatchment_laf != ''   "
                :href="attatchment_laf"
            >Download</a
            > </div>
            <div> Attachment ODS : <a
                target="_blank"
                v-if="attatchment_ods != ''   "
                :href="attatchment_ods"
            >Download</a
            ></div>
            <div> Notes : </div>
            <template v-for="note in notes">
              <div v-bind:key="note" class="w-full"><strong>{{note}}</strong>
              </div>
            </template>
          </div>
        </div>
      </vs-prompt>
    </div>
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            >Close
            </vs-button>
          </vs-col>
          <!-- form component -->
         </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
// import { AgGridVue } from "ag-grid-vue";
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
 export default {
  components: {
    // AgGridVue,
  },
  props: {
    baseUrl: {
      type: String,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    docType: {
      type: String,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      closeDetail: false,
      activePromptDetail: false,
      attatchment_laf: "",
      attatchment_ods: "",
      notes: "",
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm(indextr) {
      this.detail = true;
      this.activePromptDetail = true;
      this.attatchment_laf = this.table.data[indextr]["attachment_laf"]
      this.attatchment_ods = this.table.data[indextr]["attachment_ods"]
      this.notes = this.table.data[indextr]["notes"]
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          { field: "document_type", title: "Document Type" },
          { field: "supplier_code", title: "Supplier Code" },
          { field: "vendor_name", title: "Vendor Name" },
          { field: "reference", title: "Reference" },
          { field: "invoice_date", title: "Invoice Date" },
          { field: "invoice_value", title: "Invoice Value" },
          { field: "barcode", title: "Barcode" },
          { field: "scan_date", title: "Scan Date" },
          { field: "scan_time", title: "Scan time" },
          { field: "document_status", title: "Document Status" },
          { field: "workflow_complete_date", title: "Workflow Complete Date" },
          { field: "workflow_complete_time", title: "Workflow Complete Time" },
          { field: "created_by", title: "Create By" },
          { field: "priority", title: "Priority" },
          { field: "sla_due_date", title: "SLA Due Date" },
          { field: "aging_in_days", title: "Aging In Days" },
          { field: "reason", title: "Reason" },
          { field: "reason_date", title: "Reason Date" },
          // { field: "notes", title: "Notes" },
          { field: "po_type", title: "PO Type" },
          { field: "po_number", title: "PO Number" },
          { field: "last_username", title: "Last Username" },
          { field: "inflow_document_status", title: "Inflow Document Status" },
          { field: "posting_date", title: "Posting Date" },
          { field: "internal_invoice_number", title: "Internal Invoice Number" },
          { field: "document_payment", title: "Document Payment" },
          { field: "transaction_date_payment", title: "Transaction Date Payment" },
          { field: "barcode_laf", title: "Barcode LAF" },
          { field: "laf_upload_date", title: "LAF Upload date" },
          // { field: "attachment_ods", title: "Attachment ODS" },
          // { field: "attachment_laf", title: "Attachment LAF" },
        ],
      };
    },

    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              // status: this.status,
              territory_ids: this.territoryIDs,
              start_date: this.startDate
                ? moment(this.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.endDate
                ? moment(this.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((resp) => {
            this.tableDefaultState();
            if (resp.status === "success") {
              console.log( resp.data.report)
              this.table.total = resp.data.record_total;
              this.table.totalPage = resp.data.page_total;
              this.table.totalSearch = resp.data.record_total_search;
              this.table.length = resp.data.record_total_per_page;
              this.table.data = resp.data.report || resp.data.record_total !==0 ? resp.data.report : [];
              this.setStartEnd();
            } else {
              this.table.total = 0;
              this.table.totalPage = 0;
              this.table.totalSearch = 0;
              this.table.length = 0;
              this.table.data =     [];
              this.setStartEnd();
              this.$vs.loading.close();
            }
            this.$forceUpdate()
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      if (!val || typeof val === "undefined" || val.toString().includes("0001")) {
        return  ""
      }
      return moment(val).format("YYYY-MM-DD");
    },
    handleExport(file) {
      this.$vs.loading();
      var filetitle =
        "SUPPLIER_INVOICE_OCR_REPORT_" +  this.docType +    "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");
      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            start_date: moment(this.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.endDate).format("YYYY-MM-DD"),
            file: file,
            title: filetitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
  },
  watch: {
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
    docType() {
      this.getData();
    },
    draw(val) {
      if (val > 0) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
